.borderBlock {
  border: 1px #ddd solid;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  background: #fff;
  margin-bottom: 20px;
}
.borderBlock .item + .item {
  border-left: 1px #ddd solid;
  padding-left: 40px;
  margin-left: 40px;
}
.borderBlock .item {
  display: flex;
  align-items: center;
  height: 20px;
}
.borderBlock .item label {
  width: 100px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.borderBlock .item span {
  font-size: 16px;
}
.borderBlock .item span.strong {
  font-weight: 700;
}
.table {
  border-top: 2px #7d7d7d solid;
}
.table .ant-table-thead > tr > th {
  background: #fff;
}
.table .ant-table-thead > tr > th.color-cell,
.table .color-cell {
  background: #f8f9fd;
}

.table .complex-cell {
  display: flex;
  align-items: center;
}
.table .complex-cell .thumb {
  width: 114px;
  height: 114px;
  margin-right: 20px;
  border: 1px #ddd solid;
}
.table .complex-cell .contents {
  flex: 1;
}
.table .complex-cell .contents label {
  font-size: 14px;
}
.table .complex-cell .contents h4 {
  font-size: 16px;
  font-weight: 700;
}
.table .complex-cell .contents span {
  font-size: 14px;
  color: #ddd;
}

.table .ant-table .color-cell {
  border-right: 1px #ddd solid !important;
}
.tableStyleBox {
  border-top: 4px #7d7d7d solid;
  background: #fff;
  display: flex;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: stretch;
  }
}
.tableStyleBox .infoCell {
  flex: 1;
}
.tableStyleBox .infoCell .item {
  flex: 1;
  padding: 0 20px;
  margin: 20px 0 40px;
}
.tableStyleBox .infoCell .item ul {
  margin: -5px 0;
  padding: 0;
}
.tableStyleBox .infoCell .item li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  list-style-type: none;
}
.tableStyleBox .infoCell .item li span {
  font-size: 14px;
  color: #7d7d7d;
}
.tableStyleBox .infoCell .item p {
  font-size: 18px;
  margin: 10px 0 0;
  text-align: right;
}
.tableStyleBox .infoCell .item + .item {
  border-left: 1px #ddd solid;
}
.tableStyleBox .infoCell .bottomRow {
  border-top: 1px #ddd solid;
  padding: 20px;
  display: flex;
}
.tableStyleBox .infoCell .bottomRow label {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  margin-right: 20px;
}
.tableStyleBox .infoCell .bottomRow p {
  font-size: 14px;
  line-height: 30px;
  margin-right: 20px;
}
.tableStyleBox .infoCell .bottomRow p span {
  display: block;
  font-size: 12px;
  color: #7d7d7d;
}
.tableStyleBox .infoCell .cellTitle {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
}
.tableStyleBox .paymentCell {
  background: #3d3d3d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  box-sizing: border-box;
  padding: 20px;

  @media (max-width: 1023px) {
    width: auto;
  }
}
.tableStyleBox .paymentCell dl:after {
  display: block;
  content: '';
  clear: both;
}
.tableStyleBox .paymentCell dt {
  font-size: 14px;
  flex: 0;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1;
  float: left;
}
.tableStyleBox .paymentCell dd {
  flex: 1;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1;
  text-align: right;
}
.tableContentList {
  padding: 20px;
  list-style-type: none;
  margin: 0;
}
.tableContentList:after {
  display: block;
  content: '';
  clear: both;
}
.tableContentList li {
  float: left;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
}
.tableContentList li:after {
  display: block;
  content: '';
  clear: both;
}
.tableContentList li label {
  float: left;
  width: 140px;
  font-size: 14px;
}
.tableContentList li span {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}
.partTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 30px;
}
.totalPrice {
  display: flex;
}
.totalPrice label {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.totalPrice p {
  font-size: 18px;
  flex: 1;
  text-align: right;
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.totalPrice p span {
  font-size: 14px;
}

@primary-color: #FF7200;