.container {
  display: flex;
  flex-direction: row;

  margin-top: 16px;
}
.button {
  background: #fff;
  border: 1px solid #ccc;
  height: 36px;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 0 15px;
  cursor: pointer;
}
.icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.text {
  font-size: 13px;
  color: #333;
}
@media screen and (max-width: 360px) {
  .container {
    margin-top: 10px;
    position: relative;
    bottom: auto;
  }
}
