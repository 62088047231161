.search_form {
  max-width: 1100px;
  margin-bottom: 30px;

  :global(.ant-form-item) {
    min-height: 36px;
    align-items: center;
  }

  :global(.ant-form-item-label) > label {
    font-size: 12px;
    font-weight: bold;
    color: #777;
  }

  :global(.ant-form-item-control-input) {
    min-height: 36px;
    :global(.ant-radio-button-wrapper) {
      line-height: 34px;
      height: 36px;
    }
  }

  .footer {
    margin-top: 10px
  }
}

@primary-color: #FF7200;