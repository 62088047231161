.targetButton {
  width: 110px;
  height: 90px;
  border: solid 1px #dddddd;
  padding: 0px;
  border: 0;
  appearance: none;
  margin-right: 4px;
  cursor: pointer;
}

@primary-color: #FF7200;