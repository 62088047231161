@import '~antd/dist/antd.less';
html,
body {
  font-family: 'Montserrat', 'NotoSansKR' !important;
  background: #f9f9f9;
}

#root {
  height: 100%;
}

@media(max-width: 767px) {
  .popspace {
    display: flex;
    flex-direction: column;
  }
}

.drop-over-downward td {
  border-bottom: 2px solid #000 !important;
}

.drop-over-upward td {
  border-top: 2px solid #000 !important;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  font-size: 15px;
  font-weight: bold;
  padding: 5px 16px;
  padding-left: 48px;
  margin-top: 5px;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group {
  margin-bottom: 5px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-list {
  padding-left: 10px;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 16px;
}

.ant-page-header {
  padding: 0px 0px 24px;
}

.ant-page-header-heading-sub-title {
  font-size: 11px;
}
.ant-page-header-heading-left {
  align-items: baseline;
}

.ant-table {
  font-size: 14px;
  background: transparent;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 4px 8px;
  min-height: 45px;
  height: 45px;
}

.ant-table-thead > tr > th {
  background: #fff;
  height: 50px;
  min-height: 50px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #333;
  text-align: center !important;

  &:first-child {
    border-left: 1px solid #e0e0e0;
  }
  &:last-child {
    border-right: 1px solid #e0e0e0;
  }
}

.ant-form-horizontal .ant-form-item-label {
  min-width: 150px;
  text-align: left;

  & > label::after {
    display: none;
  }
}
.ant-form-item {
  margin-bottom: 0px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px;
}

.ant-input-affix-wrapper {
  min-height: 36px;
}

.ant-picker {
  min-height: 32px;
  display: flex;
}

.ant-picker-range {
  display: flex;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
}
.ant-descriptions-item-label {
  width: 190px;
  background: #bfbfbf !important;
  color: #fff;
}

.rdw-dropdown-selectedtext {
  min-width: 80px;
}
.rdw-editor-main {
  background: #fff;
}

.tr-bg-red * {
  background: #db2929;
  color: #fff;
}

@media (max-width: 1023px) {
  .ant-descriptions-row {
    display: block;
  }
  .ant-descriptions-item-label {
    display: block;
  }
  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 10px;
  }
}

.file-dnd {
  display: block;
}
.file-dnd .ant-upload-draggable-list-item {
  border-left: 2px dashed rgba(0, 0, 0, 0);
  border-right: 2px dashed rgba(0, 0, 0, 0);
  height: 104px;
}
.file-dnd .ant-upload-draggable-list-item.drop-over-downward {
  border-right-color: #1890ff;
}
.file-dnd .ant-upload-draggable-list-item.drop-over-upward {
  border-left-color: #1890ff;
}

.calc_table {
  width: 100%;
  border: 1px solid #444444;
  border-collapse: collapse;
  margin-bottom: 20px;

  th {
    padding: 5px 10px;
    background: #e0e0e0;
  }
  td {
    padding: 5px;
    text-align: center;
  }

  th,
  td {
    border: 1px solid #444444;
  }
}

.tableWrapCover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ant-table-pagination.ant-pagination {
  position: absolute;
  margin: 16px 0px 0px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 1023px) {
    position: relative;
  }
}

@primary-color: #FF7200;