.labelrowButtonSet {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.basicButton {
  background: #fff;
  border: 1px solid #ccc;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 0 15px;
}
.basicButtonText {
  font-size: 13px;
  color: #333;
}

.basicButton_dark {
  background: #3d3d3d;
  border-color: #3d3d3d;
  font-size: 14px;
  color: #fff;
}

@primary-color: #FF7200;