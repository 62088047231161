.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.toolbar {
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justifycontent: space-between;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.pageHeader h2 {
  font-size: 24px;
  color: #333;
  font-weight: 700;
}

.pageHeader .addition {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -5px;
}

.pageHeader .addition > * {
  margin: 0 5px;
}

.flexrow {
  flex-direction: row;
  display: flex;
  margin: 0 -15px;
  align-items: stretch;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.canvas {
  margin: 0 15px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 15px;
}
.canvas_dataform {
  display: flex;
  max-width: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}
.canvas .title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 20px;
}
.canvas .count {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 30px;
}
.canvas .prevCount {
  font-size: 16px;
  margin: 0 0 10px;
}
.canvas .compare {
  font-size: 16px;
  margin: 0 0 10px;
}
.canvas .canvasHeader {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.canvas .canvasHeader .title {
  margin: 0;
}

@primary-color: #FF7200;