.login_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 400px;

  h2 {
    color: #FF7200;
    font-size: 34px;
    margin-bottom: 30px;
    letter-spacing: -0.1rem;
  }

  .input {
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    :global(.ant-input-prefix) {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 500;
      min-width: 100px;
      color: #999;
      letter-spacing: -0.01rem;
    }
  }

  .submit {
    margin-top: 15px;
    height: 60px;
  }
}

@primary-color: #FF7200;