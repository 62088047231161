:global(.ant-notification-notice-error) {
  background: #db2929;
  :global(.anticon.ant-notification-notice-icon-error),
  :global(.ant-notification-notice-message) {
    color: #fff;
  }
}
.basic_layout {
  & > aside {
    position: fixed;
    background: @primary-color;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 240px;
    min-width: 240px;
    transition: all 200ms ease-in-out;
    overflow: hidden;
    z-index: 101;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      width: 80%;
      transform: translateX(-100%);
    }

    .bi {
      position: relative;
      padding: 40px 20px;
      z-index: 10;

      img {
        max-width: 200px;
        width: 160px;
        height: 35px;
        object-fit: contain;
        transition: all 200ms ease-in;
      }

      :global(.ant-btn) {
        display: none;
      }

      @media (max-width: 1023px) {
        width: 100%;
        position: relative;
        padding: 20px 20px;

        :global(.ant-btn) {
          display: block;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 20px;
        }
      }
    }

    .intro_bg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      //background: url('../assets/bg_login_adv.png') no-repeat center;
      opacity: 0;
      transition: opacity 180ms ease-in;
      padding: 0px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 50vw;
    }

    .center_contents {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      opacity: 0;
      transition: opacity 180ms ease-in;
      padding: 0px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      @media (max-width: 1023px) {
        position: relative;
        padding: 30px 20px;
      }
    }

    .aside_body {
      flex: 1;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.4);
      }
      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
      }
    }
  }
  .auth_body {
    padding-left: 50%;
    position: absolute;
    width: 100%;
    min-height: 100%;
    background: #ffffff;
    opacity: 0;
    transition: opacity 100ms ease-in;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1023px) {
      padding-left: 0px;
      width: 100%;
      min-height: 0%;
    }
  }
}

.basic_layout.side_open > aside {
  @media (max-width: 1023px) {
    transform: translateX(0%);
  }
}

.basic_layout.auth {
  & > aside {
    width: 50%;
    z-index: 101;

    @media (max-width: 1023px) {
      transform: translateX(0%) !important;
      width: 100%;
      position: relative;
    }

    .bi img {
      width: 200px;
      height: 40px;
      object-fit: contain;
      margin-left: 35px;

      @media (max-width: 1023px) {
        max-width: 150px;
        margin-left: 0px;
      }
    }

    .intro_bg {
      opacity: 1;
    }
    .center_contents {
      opacity: 1;
    }
  }
  .auth_body {
    opacity: 1;
    @media (max-width: 1023px) {
      position: relative;
      padding: 50px 0px;
    }
  }
}

.basic_layout .menu {
  position: relative;
  z-index: 101;
  & > :global(.ant-menu) {
    background: transparent;
    border-right: none;
    & > :global(.ant-menu-item) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    & > :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    & > :global(.ant-menu-submenu) > :global(.ant-menu) {
      background: rgba(0, 0, 0, 0.2);
      padding: 5px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  //:global(.ant-menu-item-selected) {
  //  color: #fff;
  //}
  //
  //:global(.ant-menu-submenu-title) {
  //  background: transparent;
  //  color: rgba(255, 255, 255, 0.5);
  //}
}

//.basic_layout .menu > ul {
//  list-style-type: none;
//  padding: 0px 0px 0px 20px;
//  margin: 0px;
//}
//
//.basic_layout .menu > ul > li {
//  border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
//}
//
//.basic_layout .menu > ul > li > a {
//  display: flex;
//  flex-direction: row;
//  padding: 10px 0px;
//  color: #99b1fe;
//}
//
//.basic_layout .menu > ul > li > a:hover {
//  color: #ffffff;
//}
//
//.basic_layout .menu > ul > li > a .icon {
//  display: inline-block;
//  width: 24px;
//  font-size: 12px;
//  transition-duration: 0.2s;
//}
//
//.basic_layout .menu > ul > li > a .label {
//  font-size: 12px;
//  line-height: 20px;
//  font-weight: 500;
//  letter-spacing: -0.05rem;
//  transition-duration: 0.2s;
//}
//
//.basic_layout .menu > ul > li > ul {
//  background: rgba(0, 0, 0, 0.25);
//  list-style-type: none;
//  margin: 0px 0px 0px -20px;
//  padding: 5px 0px 5px 44px;
//}
//
//.basic_layout .menu > ul > li > ul > li a {
//  display: block;
//  font-size: 12px;
//  line-height: 35px;
//  color: rgba(255, 255, 255, 0.4);
//}

.body {
  margin-left: 240px;
  padding: 80px 20px 20px;

  @media (max-width: 1023px) {
    margin-left: 0px;
  }

  & > header {
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 0px;
    left: 260px;
    right: 20px;
    padding: 10px 0px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(250, 249, 249);
    z-index: 100;

    .menu_button {
      display: none;
    }

    @media (max-width: 1023px) {
      left: 20px;

      .menu_button {
        display: flex;
      }
    }
  }
}

.modalTitle {
  font-size: 20px;
  color: #555;
  font-weight: 700;
}

.modalSubTitle {
  font-size: 16px;
  color: #555;
  font-weight: 500;
}

.modalThumbWrapper {
  padding: 22px 0;
  border-bottom: 4px #ddd solid;
}

.modalThumb {
  width: 86px;
  height: 86px;
  margin: 0 auto;
  position: relative;
  background-color: #eee;
  border-radius: 86px;
}

.inputWrapper {
  display: flex;
  margin-bottom: 10px;
  height: 56px;
  border-radius: 28px;
  border: 1px #ddd solid;
  align-items: center;
  padding: 0 30px;
}

.inputWrapper label {
  font-size: 16px;
  color: #000;
  width: 100px;
}

.inputWrapper span,
.inputWrapper input,
.inputWrapper a {
  color: #aaa;
  align-items: center;
  display: flex;
  flex: 1;
}

.inputWrapper input {
  border: 0;
}

.inputLabel {
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.35px;
  display: block;
}

@primary-color: #FF7200;